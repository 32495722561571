<template>
  <div
    class="billing-details mt-1"
    style="margin: 2rem"
  >
    <div class="d-flex align-items-center d-sm-flex d-block mb-1 card-wrapper">
      <div
        class="d-flex w-100 breadcrumb-wrapper justify-content-start"
      >
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </div>
      <div
        class="d-flex w-100 font-weight-bold button-style justify-content-end button-wrapper"
      >
        <div
          class="business-wrapper"
        >
          <span class="d-flex justify-content-center align-items-center pr-1">
            <div>
              <img
                :src="getCardBrandIconUrl(getCurrentUserStripePaymentCardBrandType)"
                class="cursor-pointer card-class"
                alt="card-icon"
                size="lg"
              >
            </div>
            <div
              v-if="getCurrentUserStripePaymentCardNumber"
              class="card-number"
            >
              XXXX-XXXX-XXXX-{{ getCurrentUserStripePaymentCardNumber }}
            </div>
          </span>
          <span>
            <v-select
              v-model="currentState"
              class="mr-1 min-width"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="value"
              :clearable="false"
              :searchable="false"
              :options="currentStateOptions"
            />
          </span>
        </div>
        <span
          class="buttons"
        >
          <b-button
            variant="primary"
            :disabled="spinner"
            @click="changePaymentCard()"
          >
            Change Card
          </b-button>
        </span>
      </div>
    </div>
    <ChangeCard
      ref="changePaymentCardModal"
      @getAllInvoicesOfUser="getAllInvoicesOfUser(paginationObject)"
    />
    <b-card no-body>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.perPage"
        :columns="8"
        :table-props="{
          bordered: true,
          striped: true,
          tableClass: 'skeleton-loading-table',
        }"
      />
      <b-table
        v-else
        id="allInvoices-table"
        hover
        selectable
        :select-mode="selectMode"
        :items="allInvoices"
        responsive
        sticky-header="true"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: Group-name -->
        <template #cell(GROUP_NAME)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.user_has_group.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Payment-type -->
        <template #cell(PAYMENT_TYPE)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.payment_for }}
            </span>
          </b-media>
        </template>

        <!-- Column: Card-no -->
        <template #cell(CARD_NO)="data">
          <b-media vertical-align="center">
            <img
              v-if="
                data.item.card_object && data.item.card_object.brand
                  ? data.item.card_object.brand
                  : null
              "
              :src="getSmallCardBrandIconUrl(data.item.card_object.brand)"
              class="cursor-pointer card-class"
              alt="card-icon"
              size="xsm"
            >
            <span
              v-if="
                data.item.card_object && data.item.card_object.last4
                  ? data.item.card_object.last4
                  : null
              "
              class="text-nowrap"
            >
              XXXX-XXXX-XXXX-{{ data.item.card_object.last4 }}
            </span>
          </b-media>
        </template>
        <!-- Column: Attempts -->
        <template #cell(NO_OF_ATTEMPTS)="data">
          <b-media
            vertical-align="center"
            class="text-nowrap pl-2"
          >
            <span class="text-nowrap">
              {{ data.item.attempts ? data.item.attempts : "---" }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount -->
        <template #cell(AMOUNT)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap"> $ {{ data.item.amount }} </span>
          </b-media>
        </template>
        <!-- Column: Last Attempt -->
        <template #cell(LAST_ATTEMPT)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap">
              {{ data.item.last_attempt | timeFormateConverter }}
            </span>
          </b-media>
        </template>

        <!-- Column: Invoice Date -->
        <template #cell(INVOICE_DATE)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap">
              {{ data.item.charged_on | timeFormateConverter }}
            </span>
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(STATUS)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.payment_status
            )}`"
            class="text-capitalize"
          >
            {{ data.item.payment_status }}
          </b-badge>
        </template>
        <!-- Column: Status -->
        <template #cell(ACTIONS)="data">
          <span v-if="data.item.payable">
            <b-button
              pill
              variant="primary"
              size="sm"
              @click="goForPaymentFunc(data.item.amount, data.item.id)"
            >
              Pay Now
            </b-button>
          </span>
          <span v-else> --- </span>
        </template>
        <!-- Column: pay button -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            /></b-col>
        </b-row>
      </div>
    </b-card>
    <div class="mt-0">
      <div class="text-nowrap">
        <!-- pagination -->
        <CustomPagination
          :total="paginationObject.total"
          :per-page="paginationObject.limit"
          :page="paginationObject.offset"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  VBToggle,
  BBadge,
  BSkeletonTable,
  // BPagination,
  VBTooltip,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
// import vSelect from 'vue-select'
import { showToast } from '@/common/global/functions'
import MemberType from '@/common/enums/memberTypeEnum'
import InvitationStatusType from '@/common/enums/invitationStatusEnum'
import store from '@/store'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import ChangeCard from '@/views/groups/ChangeCard.vue'
import moment from 'moment'
import CustomPagination from '@/components/common/CustomPagination.vue'
import vSelect from 'vue-select'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    ChangeCard,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BSkeletonTable,
    BButton,
    vSelect,
    BreadCrumb,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
    timeFormateConverter(date) {
      if (date) {
        return moment.utc(date).format('DD-MM-YYYY h:mm a')
      }
      return '---'
    },
  },
  async beforeRouteEnter(to, from, next) {
    const groupUser = await store.getters['user/getSelectedGroupMember']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (groupUser === null) {
      next({
        name: 'pricing-plans',
      })
    } else if (isUserAdmin || groupUser.role !== MemberType.OWNER) {
      next({
        name: '/',
      })
    } else {
      next()
    }
  },
  data() {
    return {
      MemberType,
      InvitationStatusType,
      amount: null,
      active: false,
      spinner: false,
      spinnerForPayment: false,
      allInvoices: [],
      invoiceId: null,
      tableColumns: [
        { key: 'GROUP_NAME', label: 'BUSINESS NAME' },
        { key: 'PAYMENT_TYPE' },
        { key: 'CARD_NO' },
        { key: 'AMOUNT' },
        { key: 'NO_OF_ATTEMPTS' },
        { key: 'LAST_ATTEMPT' },
        { key: 'INVOICE_DATE' },
        { key: 'STATUS' },
        { key: 'ACTIONS' },
      ],
      selectMode: 'single',
      selected: [],
      selectedGroupId: null,
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Businesses', to: '/groups-listing', active: false },
        {
          name: 'Applications',
          to: `/applications?groupId=${this.$route.query.groupId}`,
          active: false,
        },
        { name: 'Billing Details', to: null, active: true },
      ],
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      currentState: { key: 1, value: 'Current Business' },
      initialState: 'current group',
      currentStateOptions: [
        { key: 1, value: 'Current Business' },
        { key: 2, value: 'All Businesses' },
      ],
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters['user/getUserId']
    },
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember
        ? this.getSelectedGroupMember.role
        : null
    },
    getCurrentUserStripePaymentCardNumber() {
      return this.$store.getters['user/getCurrentUserStripePaymentCardNumber']
    },
    getCurrentUserStripePaymentCardBrandType() {
      return this.$store.getters['user/getCurrentUserStripePaymentCardBrandType']
    },
  },
  watch: {
    currentState: {
      handler(value) {
        if (value.value === 'Current Business') {
          this.initialState = 'current group'
        } else {
          this.initialState = 'all groups'
        }
        this.pageChanged(1)
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('user/setAllUserGroupIfNotPresent')
    this.selectedGroupId = this.$route.query.groupId
    await this.getAllInvoicesOfUser(this.paginationObject)
  },
  methods: {
    getCardBrandIconUrl(img) {
      const images = require.context('@/assets/images/cardBrandIcons/', false, /\.png$/)
      return images(`./${img}.png`)
    },
    getSmallCardBrandIconUrl(img) {
      const images = require.context('@/assets/images/cardBrandIcons/smallCardBrandIcons/', false, /\.png$/)
      return images(`./${img}.png`)
    },
    openThePopUpForPayment() {
      this.$swal({
        title:
          '<span class="font-weight-bolder text-primary">Invoice Payment</span>',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/simiicons/Image(2).svg'),
        text: `Pay invoice amount $ ${this.amount}`,
        confirmButtonText: 'Pay',
        showLoaderOnConfirm: true,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
        preConfirm: () => this.$axios
          .post('payment/pay-now-invoice', { invoiceId: this.invoiceId })
          .then(() => {
            showToast('Invoice Payment', 'Transaction succeeded')
            this.getAllInvoicesOfUser(this.paginationObject)
          })
          .catch(() => {
            this.$swal({
              title:
                    '<span class="font-weight-bolder ">Your payment is declined, please change your card!</span>',
              showCancelButton: true,
            }).then(result => {
              if (result.value) {
                this.changePaymentCard(this.invoiceId, this.amount)
                // this.$router.push({
                //   name: 'change-card',
                //   query: { groupId: `${this.$route.query.groupId}`, invoiceId: `${this.invoiceId}` },
                // })
              }
            })
          }),
        allowOutsideClick: () => !this.$swal.isLoading(),
      })
    },
    canEditUpdate(role) {
      switch (this.getMemberRoleInSelectedGroup) {
        case MemberType.OWNER:
          return role !== MemberType.OWNER
        case MemberType.SUPER_MEMBER:
          return role === MemberType.MEMBER
        default:
          return false
      }
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllInvoicesOfUser(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllInvoicesOfUser(this.paginationObject)
    },
    async getAllInvoicesOfUser({ offset, limit }) {
      this.paginationObject = { ...this.paginationObject, offset, limit }
      this.spinner = true
      let groupId = null
      if (this.currentState.value === 'Current Business') {
        groupId = Number(this.selectedGroupId)
      }
      try {
        let res
        if (groupId) {
          res = await this.$axios.get(
            `payment/get-all-invoices-of-user?offset=${offset - 1}&limit=${limit}&groupId=${groupId}`,
          )
        } else {
          res = await this.$axios.get(
            `payment/get-all-invoices-of-user?offset=${offset - 1}&limit=${limit}`,
          )
        }
        const { data: resData } = res || {}
        const { data } = resData || {}
        this.allInvoices = data.results || {}
        this.paginationObject.total = data.total
        this.spinner = true
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        showToast('Billing Details', message.toString(), 'danger')
      }
      this.spinner = false
    },
    changeState() {
      this.active = false
    },
    goForPaymentFunc(amount, id) {
      this.amount = amount
      this.invoiceId = id
      this.openThePopUpForPayment()
    },
    resolveUserStatusVariant(Status) {
      if (Status === 'generic_decline') return 'secondary'
      if (Status === 'succeeded') return 'success'
      return 'warning'
    },
    memberStatusTextFun(Status) {
      if (Status === InvitationStatusType.PENDING) return 'Pending'
      if (Status === InvitationStatusType.APPROVED) return 'Approved'
      return 'Rejected'
    },
    changePaymentCard(invoiceId, amount) {
      this.$refs.changePaymentCardModal.show(invoiceId, amount)
      // this.$router.push({
      //   name: 'change-card',
      //   query: { groupId: `${this.$route.query.groupId}` },
      // })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.billing-details {
  .min-width{
    min-width: 165px !important;
  }
  #time-state-select {
    .vs__selected-options {
      min-width: 100px !important;
    }
    .vs__dropdown-menu {
      min-width: 100px !important;
    }
    .vs__dropdown-toggle {
      min-width: 100px !important;
      background-color: white !important;
    }
  }
  .vs__dropdown-toggle {
    background-color: white !important;
  }
  #per-page-selector {
    .vs--single .vs__dropdown-toggle {
      max-height: 37px !important;
    }
    .vs__dropdown-toggle {
      min-width: 90px !important;
    }
  }
  body {
    line-height: 0 !important;
  }
  .dropdown-item {
    padding-bottom: 5px !important;
  }
  .dropdown-menu {
    padding: 0px !important;
  }
  .b-table-sticky-header {
    overflow-y: auto;
    //min-height: 120px !important;
  }
  .b-table-sticky-header {
    overflow-y: auto;
    //min-height: 120px !important;
    max-height: 100% !important;
  }
  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .table th,
  table td {
    padding: 1rem 1rem !important;
  }
  .badge:empty {
    display: inline !important;
  }
  .badge {
    padding: 8px !important;
  }
  .badge-pill {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    border-radius: 10rem !important;
  }
  .card-class {
    height: 25px;
    margin-right: 5px;
  }
  .button-style {
    padding-right: 2rem;
  }
  .business-wrapper{
    display: flex;
  }
  .v-select{
    font-size: 14px;
  }
  @media (max-width: 970px) {
  .card-wrapper{
    flex-direction: column;
    justify-content: flex-start;
  }
  .breadcrumb-wrapper{
    width: 100% !important;
  }
  .button-wrapper{
    width: 100% !important;
    padding-right: 0rem;
  }
  .v-select{
    font-size: 12px;
  }
  .card-number{
    font-size: 12px;
  }
}
@media (max-width: 570px) {
  .button-wrapper{
    flex-direction: column;
    align-items: flex-end;
  }
  .business-wrapper{
    width : 100%;
    justify-content: flex-end;
  }
  .buttons{
    margin-top: 1rem;
    margin-right: 1.5rem;
  }
  .v-select{
    font-size: 10px;
  }
}
@media (max-width: 450px) {
  .card-number{
    font-size: 10px;
  }
}
}
</style>
